import React from "react";
import "../components/ServiceBox.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

function ServiceBox(props) {
  const Bold = ({ children }) => <strong>{children}</strong>;
  const Text = ({ children }) => <p>{children}</p>;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  };

  return (
    <div className="service-box" id={props.id}>
      <div className="row align-items-center">
        <div className="col-md-8 service-box-texts">
          <h2>{props.title}</h2>
          {renderRichText(props.description, options)}
        </div>
        {props.img.file && (
          <div className="col-md-4">{<img src={props.img.file.url} alt="" className="img-fluid service-img" />}</div>
        )}
      </div>
    </div>
  );
}

export default ServiceBox;
