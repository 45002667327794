import * as React from "react";
import "../../scss/main.scss";
import { graphql, useStaticQuery } from "gatsby";

import Navbar from "../../components/Navbar-en";
import { Helmet } from "react-helmet";
import HeroSection from "../../components/HeroSection";
import ServiceBox from "../../components/ServiceBox";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer-en";
import WhatsappButton from "../../components/WhatsappButton";

import { slugify } from "../../utils/helpers";

import 'bootstrap/dist/css/bootstrap.min.css';

const ServiciosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulService(sort: { fields: title_en, order: ASC }) {
        nodes {
          description_en {
            raw
          }
          title_en
          image {
            file {
              url
            }
          }
        }
      }
      allContentfulServicesPageTemplate {
        nodes {
          hero_title_en
          hero_description_en {
            hero_description_en
          }
          hero_image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const pageTitle = "Services | Virtual Ed Global"
  const heroTitle = data.allContentfulServicesPageTemplate.nodes[0].hero_title_en;
  const heroDescription = data.allContentfulServicesPageTemplate.nodes[0].hero_description_en.hero_description_en;
  const heroImg = data.allContentfulServicesPageTemplate.nodes[0].hero_image.file.url;

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={heroTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/en/services" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImg} />
        <meta name="twitter:image" content={heroImg} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} /> 
      </Helmet>

      <Navbar />

      <div className="page-wrapper">
        <HeroSection image={heroImg} title={heroTitle} description={heroDescription} />

        <section className="service-boxes">
          <div className="container">
            {data.allContentfulService.nodes.map((item) => {
              return (
                <ServiceBox
                  title={item.title_en}
                  description={item.description_en}
                  img={item.image}
                  key={item.title_en}
                  id={slugify(item.title_en)}
                />
              );
            })}
          </div>
        </section>

        {/* Contact */}
        <section id="contacto">
          <Contact
            title={
              <p>
                <strong>We are here to talk about Higher Education.</strong> Share your comments with us.
              </p>
            }
            lang="en"
            button_label="Contact"
          />
        </section>
      </div>

      <WhatsappButton lang="en" />
      <Footer />
    </main>
  );
};

export default ServiciosPage;
